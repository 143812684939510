// @mixin flex {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   align-content: center;
// }

* {
  box-sizing: border-box;
}

body {
  background-color: #101728 !important;
}

.ourservices {
  height: 100vh;
  background: #FBF4E6;
}

div {
  padding: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.coaching {
  background-color: #101728;
  color: #FBF4E6;
  height: 30%;

  div {
    padding: 0 !important;
  }

  h1 {
    color: #FF8C2A;
    font-size: 8vh;
    font-weight: 900;
    margin: 0;
    padding: 0;
    margin-top: -2vh;
  }

  p {
    margin-top: -2vh;
    font-size: 3vh;
    margin-bottom: 2vh;
  }
}

.approach {
  width: 100vw;
  height: 60%;
  position: relative;

  h2 {
    font-size: 6vh;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 2vh;
  }

  .s2 {
    margin-left: 15vh;
    margin-top: 10vh;
    //margin-bottom: 30vh;
    //margin-right: 15vh;
    display: flex;
    font-size: 3vh;
    //justify-content: 20px;
    //flex-wrap: wrap;
  }

  .text {
    background-color: #FF8C2A;
    border: 1px #FF8C2A solid;
    padding: 2vh;
    height: 6vh;
    width: 12%;
    text-align: center;
    transform: skew(-20deg);
    font-weight: 900;
    margin: 4vw;
  }
}

.footer {
  margin-bottom: 5vh;
  margin-left: 10vh;
  font-size: 2vh;
  font-weight: 400;
  justify-content: center;
}

.footer-span1 {
  background-color: #13D1FB;
  border-radius: 5rem;
  border-style: none;
  margin: .5vh;
  padding-left: 1vh;
  padding-right: 1vh;
  padding-top: .5vh;
  padding-bottom: .5vh;
  width: auto;
  height: auto;
  font-size: 2vh;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.tooltip {
  color: white;
  font-size: 2vh;
  padding: 1vh;
  background-color: black;
  justify-content: center;
}

.tooltip1 {
  color: white;
  font-size: 3vw;
  padding: 1vw;
  background-color: black;
  justify-content: center;
  width: 50vw;
}



@media only screen and (max-width: 1000px) and (orientation: portrait) {

  .coaching {
    height: 30%;

    h1 {
      color: #FF8C2A;
      font-size: 5vh;
      font-weight: 600;
      margin-top: 0;
      margin-left: 2vh;
      padding-top: 2vh;
    }

    p {
      margin-top: 0;
      font-size: 2vh;
      padding: 2vh;
    }
  }

  .approach {
    width: 100vw;
    height: 50%;
    position: relative;

    h2 {
      font-size: 4vh;
      font-weight: 500;
      margin-top: 0;
      margin-left: 2vh;
      padding-top: 2vh;
    }

    .s2 {
      width: fit-content;
      margin-top: 2vh;
      margin-left: 0vh;
      display: grid;
      font-size: 2vh;
      gap: 4vh;
    }

    .text {
      background-color: #FF8C2A;
      //border: 1px #FF8C2A solid;
      padding: 1vh;
      height: 1vh;
      min-width: 50px;
      text-align: justify;
      font-weight: 700;
      margin-left: 4vh;
      margin-bottom: -1vh;
    }
  }

  .footer {
    margin-bottom: 2vh;
    margin-left: 2vh;
    font-size: 1.75vh;
    font-weight: 400;
    justify-content: center;
  }

  .footer-span1 {
    display: inline-grid;
    // grid-gap: 1vh;
    background-color: #13D1FB;
    border-radius: 5rem;
    border-style: none;
    margin: .75vh;
    padding-left: 1vh;
    padding-right: 1vh;
    padding-top: .25vh;
    padding-bottom: .25vh;
    width: auto;
    height: auto;
    font-size: 1.5vh;
    font-weight: 500;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {

  .coaching {
    height: 40%;

    h1 {
      color: #FF8C2A;
      font-size: 4vw;
      font-weight: 600;
      margin-top: -2vw;
      margin-left: 1vw;
      padding-top: 1vw;
    }

    p {
      margin-top: 0;
      font-size: 1.5vw;
      padding: .5vw;
      margin-left: 1vw;
    }
  }

  .approach {
    //width: 100vw;
    height: 50%;
    position: relative;

    h2 {
      font-size: 3vw;
      font-weight: 500;
      margin-top: 0;
      margin-left: 2vh;
      padding-top: 2vh;
    }

    .s2 {
      width: fit-content;
      margin-top: 2vw;
      margin-left: 15vw;
      display: block;
      font-size: 2vw;
      gap: 2vw;
    }

    .text {
      background-color: #FF8C2A;
      //border: 1px #FF8C2A solid;
      padding: 1vh;
      height: 1vh;
      min-width: 25px;
      text-align: justify;
      font-weight: 700;
      margin-left: 4vh;
      margin-bottom: -1vh;
    }
  }

  .footer {
    margin-bottom: 2vh;
    margin-left: 2vh;
    font-size: 2.5vh;
    font-weight: 400;
    justify-content: center;
  }

  .footer-span1 {
    display: inline-grid;
    // grid-gap: 1vh;
    background-color: #13D1FB;
    border-radius: 5rem;
    border-style: none;
    margin: .75vh;
    padding-left: 1.5vh;
    padding-right: 1.5vh;
    padding-top: .25vh;
    padding-bottom: .25vh;
    width: auto;
    height: auto;
    font-size: 2.5vh;
    font-weight: 700;
    align-items: center;
    justify-content: center;
  }

  .tooltip2 {
    color: white;
    font-size: 1.5vw;
    padding: 1vw;
    background-color: black;
    justify-content: right;
    width: 70vw;
  }
}