body {
  background-color: #101728 !important;
  height: 100vh !important;
}

.contact-form {
  margin-top: '20px';
  border: 1px solid #101728;
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 20px !important;
}

.contact-heading {
  font-size: '200px';
  text-align: left !important;
  color: #FF8C2A;
  line-height: 1.4;
}

.contact-us {
  font-size: '200px';
  color: #E863FD;
}

.email-color {
  color: blue !important;
}

@media only screen and (orientation: portrait) {
  body {
    background-color: #101728 !important;
    height: 80vh !important;
  }

  .contact-form {
    margin-top: '20px';
    border: 1px solid #101728;
    background-color: whitesmoke;
    border-radius: 1rem;
    padding: 20px !important;
    font-size: '20px' !important;
  }

  .contact-heading {
    font-size: '50px' !important;
    text-align: left !important;
    color: #FF8C2A;
    line-height: 1.4;
  }

  .contact-us {
    font-size: '100px';
    color: #E863FD;
  }

  .email-color {
    color: blue !important;
  }
}