body {
  background-color: #101728 !important;
}

.left-card-style {
  width: 50%;
  margin-right: auto;
  background: #c9e9f9;
  border: 1px solid #6fbce3;
  // border-radius: 15px;
  margin-left: 40px;
  margin-bottom: 40px;
  padding: 20px;
}

.right-card-style {
  width: 50%;
  margin-left: auto;
  background: #c9e9f9;
  border: 1px solid #6fbce3;
  // border-radius: 15px;
  margin-right: 40px;
  margin-bottom: 40px;
  padding: 20px;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .left-card-style {
    width: 100%;
    margin-right: 0px;
    background: #bfe0f1;
    // border: 1px solid #8c6008;
    // border-radius: 15px;
    margin-left: 0px;
    margin-bottom: 40px;
    padding: 20px;
  }

  .right-card-style {
    width: 100%;
    margin-left: 0px;
    background: #ABC8D6;
    // border: 1px solid #8c6008;
    // border-radius: 15px;
    margin-right: 0px;
    margin-bottom: 40px;
    padding: 20px;
  }
}

.card-image-container {
  display: flex;
  flex-direction: row;
  margin: 30px;
  padding: 30px;
  align-items: center;
}

.card-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 20px;
  text-align: justify;
}

.service-banner-text {
  font-weight: 900;
  font-size: 80px;
  line-height: 100px;
  display: flex;
  text-align: center;
  margin-top: 0px;
}

.coaching-banner-text {
  font-weight: 900;
  font-size: 80px;
  line-height: 100px;
  display: flex;
  text-align: center;
  margin-top: 0px;
}

.coaching-banner-text1 {
  font-weight: 900;
  font-size: 50px;
  line-height: 80px;
  display: flex;
  text-align: center;
  margin-top: 0px;
}