@mixin font {
  font-family: 'Inter';
  font-style: normal;
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

body {
  background-color: #101728 !important;
}

.main_root {
  background: #101728;
  height: 80vh;
  padding: 10px;

  // div {
  //   margin-top: 6%;

  //   span {
  //     color: #FF8C2A;
  //     @include font;
  //     font-weight: 700;
  //     font-size: 30px;
  //     font-weight: 800;
  //   }

  //   .section_Agile {
  //     display: block;
  //     @include font;
  //     color: #eee;
  //     font-weight: 400;
  //     font-size: 18px;
  //     margin-top: 1%;
  //   }

  //   #span_text {
  //     color: #eee !important;
  //     @include font;
  //     font-weight: 700;
  //     font-size: 24px !important;
  //     text-transform: uppercase;
  //     font-weight: 800;
  //     margin-bottom: 3%;
  //   }

  //   .section {
  //     display: flex;
  //     margin-top: 1%;
  //     justify-content: space-between;

  //     div:nth-child(1) {
  //       @include font;
  //       color: white;
  //       width: 30%;
  //       font-size: 18px;
  //       font-weight: 600;
  //       line-height: 40px;
  //     }

  //     div:nth-child(2) {
  //       width: 40%;
  //     }
  //   }

  //   .section_two {
  //     h3 {
  //       @include font;
  //       font-size: 35px;
  //       color: #FF8C2A;
  //     }

  //     p {
  //       line-height: 40px;
  //       @include font;
  //       letter-spacing: 1px;
  //       color: #eee;
  //     }

  //     #spantext {
  //       line-height: 40px;
  //       @include font;
  //       font-weight: 400;
  //       color: #eee;
  //       font-size: 17px;
  //     }
  //   }

  // }
}

.coaching-page-main {
  // box-sizing: unset;
  margin-right: '20px';
  height: '80vh';
}

.coaching-lv-title {
  font-size: 100px;
  color: #FF8C2A;
  font-family: 'Inter';
  font-style: 'normal';
  font-weight: '900';
}

.coaching-lv-intro {
  font-size: 30px;
  color: #eff8f9;
  word-spacing: 3px;
  line-height: 1.4;
}



.grid-container {
  display: grid;
  column-gap: 50px;
  grid-template-columns: repeat(6, 1fr);
  ;
  //background-color: #2196F3;
  padding: 10px;
}

.tile_left {
  width: 200px;
  height: 100px;
  border: 1px solid black;
  // margin: 0 auto;
  /* Center the div horizontally */
  background-color: #101728;
  grid-column: 1 / 7;
}

.tile_right {
  width: 200px;
  height: 100px;
  border: 1px solid black;
  // margin: 0 auto;
  background-color: #101728;
  grid-row: 2 /3;
  grid-column: 4 / 7;
}

.ourSerivces {
  display: flex;
  justify-content: space-between;
  color: #101728;

  section:nth-child(1) {
    width: 66%;

    img {
      margin-top: 10%;
      width: 100%
    }
  }

  center {
    color: #101728;
  }

  section:nth-child(2) {
    width: 30%;
    margin-top: 5%;

    ul li {
      line-height: 35px;
    }
  }
}

.AreaOfExpertise {
  @include flex;

  #section {
    background-color: #DD02FF;
    color: #101728;
    padding: 1%;
    margin-bottom: 5%;
    width: 50%;

    div:nth-child(1) {
      display: flex;
      justify-content: space-between;

      span:nth-child(1) {
        @include font;
        font-weight: 900;
        font-size: 20px;
      }

      span:nth-child(2) {
        font-size: 30px;
      }
    }

    div:nth-child(2) {
      span {
        @include font;
        line-height: 30px;
      }
    }
  }
}

.whyus {
  display: flex;
  justify-content: space-between;

  section {
    margin-bottom: 5%;
    margin-top: 9%;

  }

  section:nth-child(1) {
    width: 25%;

    div:nth-child(1) {
      padding: 15px !important;
      background: #E863FD;
      text-align: center;
      border-radius: 10%;
      color: white;
      width: 92%;
      @include font;
      font-weight: 800;
    }

    div:nth-child(n+2) {
      width: 100%;
      border-radius: 12%;
      margin-top: 3%;
      @include font;
      font-style: italic;
      font-weight: 800;
      padding: 5px !important;
      margin-bottom: 2%;
      text-align: center;
      background: #F090FF;

      text-align: center;
    }
  }

  section:nth-child(2) {
    width: 25%;

    div:nth-child(1) {
      padding: 15px !important;
      background: #FF5F84;
      text-align: center;
      border-radius: 10%;
      color: white;
      width: 92%;
      @include font;
      font-weight: 800;
    }

    div:nth-child(n+2) {
      width: 100%;
      border-radius: 12%;
      margin-top: 3%;
      @include font;
      font-style: italic;
      font-weight: 800;
      padding: 5px !important;
      margin-bottom: 2%;
      text-align: center;
      background: #FF85A1;
      text-align: center;
    }
  }

  section:nth-child(3) {
    width: 25%;

    div:nth-child(1) {
      padding: 15px !important;
      background: #FF8C2A;
      text-align: center;
      border-radius: 10%;
      color: white;
      width: 92%;
      @include font;
      font-weight: 800;
    }

    div:nth-child(n+2) {
      width: 100%;
      border-radius: 12%;
      margin-top: 3%;
      @include font;
      font-style: italic;
      font-weight: 800;
      padding: 5px !important;
      margin-bottom: 2%;
      text-align: center;
      background: #FFB16F;
      text-align: center;
    }
  }
}

.onebackground {
  background: #47b9f3;
  // height: 100vh;
  padding-bottom: 1px !important;
}

.twobackground {
  background: #ff7998;
  // height: 100vh;
  padding-bottom: 10px !important;
}

#whyus {
  height: 100vh;
}

.secondblock {
  padding-top: 10vh;
  height: 100vh;
}

.faq {
  height: 100vh;
}

.dtcube {
  height: 40vh;
}

.menuOptionText {
  font-size: 20px;
  line-height: 22px;
  color: black;
  font-weight: 400;
}

.menuLinkText {
  text-transform: capitalize;
}