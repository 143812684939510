body {
  background-color: #101728 !important;
}

nav {

  //background: #101728;
  //color: white;
  //position: fixed;
  //width: 100%;
  //top: 10px;
  //z-index: 900;
  >div {
    padding: 0 !important;
  }
}

@mixin nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.nav {
  @include nav;
  width: 100%;
  padding-top: 10px;
}

.svg {
  fill: white;
  width: 60px;
  height: auto;
  font-weight: 600;
  margin-top: 1px;
}

.svg:hover {
  fill: grey;
}

.contact {
  fill: #101728;
  width: 60px;
  margin-top: 1px;
  height: 60px !important;
}