#app {
	height: 100%
}

html,
body {
	position: relative;
	height: 100%;
}

body {
	background: #eee;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #000;
	margin: 0;
	padding: 0;
}

.swiper {
	width: 80%;
	padding-top: 50px;
	padding-bottom: 50px;
}

.swiper-slide {
	background-position: center;
	color: white;
	display: flex;
	color: #101728;
	padding: 20px;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	align-items: center;
	background-size: cover;
	width: 50%;
	height: auto;
}


.swiper-slide img {
	display: block;
	width: 100%;
}
