body {
  background-color: #101728 !important;
  margin: 10px solid #101728;
}

.contact-form {
  margin-top: '40px' !important;
  padding: '40px' !important;
}

.contact-heading {
  font-size: '200px';
  text-align: center;
  color: #FF8C2A;
  line-height: 1.4;
}

.contact-us {
  font-size: '200px';
  color: #E863FD;
}

.email-color {
  color: aliceblue;
}