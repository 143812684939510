body {
  background-color: #101728 !important;
}

.expertise-left-card-style {
  width: 50%;
  margin-right: auto;
  // border: 1px solid #000000;
  // border-radius: 15px;
  margin-left: 40px;
  margin-bottom: 40px;
  background: #ffeaec;
  border: 1px solid #f6afb6;
}

.expertise-right-card-style {
  width: 50%;
  margin-left: auto;
  background: #ffeaec;
  border: 1px solid #f6afb6;
  // border-radius: 15px;
  margin-right: 40px;
  margin-bottom: 40px;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .expertise-left-card-style {
    width: 100%;
    margin-right: 0px;
    // background: #f5a561;
    // border: 1px solid #8c6008;
    // border-radius: 15px;
    margin-left: 0px;
    margin-bottom: 40px;
  }

  .expertise-right-card-style {
    width: 100%;
    margin-left: 0px;
    // background: #f5a561;
    // border: 1px solid #8c6008;
    // border-radius: 15px;
    margin-right: 0px;
    margin-bottom: 40px;
  }
}

.expertise-card-image-container {
  display: flex;
  flex-direction: row;
  margin: 30px;
  padding: 30px;
  align-items: center;
}

.expertise-card-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 20px;
  text-align: justify;
}

.expertise-banner-text {
  font-weight: 900;
  font-size: 80px;
  line-height: 100px;
  display: flex;
  text-align: center;
  margin-top: 0px;
}