// Om changes

* {
  box-sizing: border-box;
}

body {
  background-color: #101728 !important;
}

@mixin Home {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

@mixin box-shadow($top: 3px, $left: 3px, $blur: 4px, $colour: #756c5c, $inset: "") {
  -webkit-box-shadow: #{$inset} $top $left $blur $colour;
  -moz-box-shadow: #{$inset} $top $left $blur $colour;
  box-shadow: #{$inset} $top $left $blur $colour;
}

html body {
  height: 100%;
  min-width: 100%;
  font-size: 12px;
}

.main {
  color: #FBF4E6;
  height: 100vh;
  max-width: 100vw;
  background: #101728;
}

.nav {
  @include Home;



  section:nth-child(1) .svg {
    fill: #FBF4E6;
    width: auto;
    height: 10vh;

  }

  section:nth-child(1) .svg:hover {
    fill: grey;
    cursor: pointer;
    transform: scale(1.2);
  }

  section:nth-child(2) .contact {
    width: auto;
    height: 15vh;
  }

  section:nth-child(2) .contact:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}

.home {
  display: grid;
  grid-template-columns: 2.75fr 1fr;
  gap: 2vw;
  height: 75vh;
  overflow: hidden;

}

.course_list {
  z-index: 1;
  position: absolute;
  bottom: 5vh;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 4.5rem;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025vh;
  overflow: hidden;
  padding-bottom: 5vh;

}

.home-divtag #navlink #span:hover,
.home-divtag #navlink .span:focus {
  border-bottom: .25vw solid #E863FD;

}


.gradient-1 {
  background: linear-gradient(45deg, #FF5E84, #FF8E26);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.services {
  text-decoration: none;
  transform: rotate(-45deg);
  position: absolute;
  padding-left: -5vw;
  height: 75vh;

}

.button {
  background: #101728;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  letter-spacing: .25vw;
}

.service {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-size: 3vh;
  line-height: 12vh;
  background: #101728;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  overflow: hidden;

}

.button-1 {
  background-color: #FF5E84;
  border-radius: 5rem;
  border-style: none;
  width: 14em;
  height: 4em;
  font-size: 1rem;
  font-style: italic;
  font-weight: 900;
  line-height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.button-2 {
  background-color: #FF8E26;
  border-radius: 5rem;
  border-style: none;
  width: 14em;
  height: 4em;
  margin-top: 3em;
  margin-left: 7em;
  font-size: 1rem;
  font-style: italic;
  font-weight: 900;
  line-height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.button-3 {
  background-color: #E863FD;
  border-radius: 5rem;
  border-style: none;
  width: 14em;
  height: 4em;
  margin-top: 3em;
  font-size: 1rem;
  font-style: italic;
  font-weight: 900;
  line-height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.button-1:hover {
  @include box-shadow;
  background-color: #fb3261 !important;
}

.button-2:hover {
  @include box-shadow;
  background-color: #f97a04 !important;
}

.button-3:hover {
  @include box-shadow;
  background-color: #dd2ff8 !important;
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {

  * {
    box-sizing: border-box;
  }



  @mixin box-shadow($top: 3px, $left: 3px, $blur: 4px, $colour: #756c5c, $inset: "") {
    -webkit-box-shadow: #{$inset} $top $left $blur $colour;
    -moz-box-shadow: #{$inset} $top $left $blur $colour;
    box-shadow: #{$inset} $top $left $blur $colour;
  }

  html body {
    height: 100%;
    min-width: 100%;
    font-size: 12px;
  }

  .main {
    color: #FBF4E6;
    height: 100vh;
    max-width: 100vw;
    background: #101728;
    border: 1em solid #101728;
  }

  .nav {
    @include Home;

    section:nth-child(1) .svg {
      fill: #FBF4E6;
      width: auto;
      height: 15vh;

    }

    section:nth-child(1) .svg:hover {
      fill: grey;

    }

    section:nth-child(2) .contact {
      width: auto;
      height: 15vh;

    }
  }

  .home {
    align-items: auto;
  }

  .course_list {
    position: absolute;
    bottom: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 12vh;
    align-items: center;
    text-align: center;
    letter-spacing: 1vh;
    overflow: hidden;

  }

  .col-sm-5 {
    margin-left: 7rem;
  }

  .button-1 {
    background-color: #FF5E84;
    border-radius: 5rem;
    border-style: none;
    width: 9em;
    height: 2.5em;
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .button-2 {
    background-color: #FF8E26;
    border-radius: 5rem;
    border-style: none;
    width: 9em;
    height: 2.5em;
    margin-top: 1.5em;
    margin-left: 3em;
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .button-3 {
    background-color: #E863FD;
    border-radius: 5rem;
    border-style: none;
    width: 9em;
    height: 2.5em;
    margin-top: 1.5em;
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .services {
    text-decoration: none;
    transform: rotate(-45deg);
    position: absolute;
    margin-left: -15vw;
    margin-top: -1vw;
    // height: 75vh;
  }

  #button_1,
  #button_2,
  #button_3 {
    font-size: 1rem;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1000px) and (orientation: portrait) {

  .home {
    flex-direction: column-reverse;
    padding-top: 3em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em;
    overflow: hidden;
    box-sizing: border-box;

  }

  .nav {
    @include Home;

    section:nth-child(1) .svg {
      fill: #FBF4E6;
      width: auto;
      height: 7vh;
      margin-left: 2em;


    }

    section:nth-child(1) .svg:focus .svg:hover {
      fill: grey;

    }

    section:nth-child(2) .contact {
      margin-right: 5em;
      width: auto;
      height: auto;
    }
  }

  .course_list {
    position: absolute;
    bottom: 8vh;
    padding-left: 1em;
    padding-right: 1em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 3.5vh;
    line-height: 6vh;
    align-items: center;
    text-align: center;
  }

  // #span:hover {
  //   fill: grey;
  // }

  .services {
    display: grid;
    grid-template-columns: 27% 27% 27%;
    grid-gap: 2%;
    width: 90%;
    text-decoration: none;
    position: relative;
    padding-top: 30%;
    padding-right: 10%;
    transform: rotate(0deg);
    box-sizing: border-box;
    padding-right: 15vw;

  }

  .button {

    background: #101728;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    letter-spacing: .15vw;
  }

  .service {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    //font-size: 3vh;
    //line-height: 8vh;
    background: #101728;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    overflow: hidden;

  }

  .button-1 {
    background-color: #FF5E84;
    border-radius: 5rem;
    border-style: none;
    width: 22vh;
    height: 5vh;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-60deg);

  }

  .button-2 {
    background-color: #FF8E26;
    border-radius: 5rem;
    border-style: none;
    width: 22vh;
    height: 5vh;
    margin-top: 0em;
    margin-left: -8vw;
    font-size: 2em;
    font-style: italic;
    font-weight: 600;
    line-height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-60deg);

  }

  .button-3 {
    background-color: #E863FD;
    border-radius: 5rem;
    border-style: none;
    width: 22vh;
    height: 5vh;
    margin-top: 0em;
    margin-left: -15vw;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
    line-height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-60deg);
  }
}