body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	scroll-behavior: smooth;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.css-yjsfm1 span {
	color: #101728 !important;
}

.divtag-main {
	display: flex;
	flex-direction: column;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 12px !important;
}

@media (max-width: 830px) {
	.divtag-main {
		display: flex;
		flex-direction: row;
	}

	.s2 {
		flex-direction: column;
		width: 25%;
	}

	#v1,
	#v2,
	#v3,
	#v4,
	#v5 {
		width: 100% !important;
		margin-top: 3%;
	}

	.s3 {
		width: 70%;
	}
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
	background-image: none !important;
}