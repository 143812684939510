@mixin agile {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;

}

body {
  background-color: #101728 !important;
}

.Agile {
  background: #101728;
  color: #FBF4E6;
  height: 100vh;
}

.header {
  padding-top: 20px;
}

.title {
  font-size: 60px;
}

.para {
  font-size: 20px;
  margin-top: -5vh;
  margin-bottom: 5vh;
  color: burlywood;
}

.main-box {
  width: 100%;
  @include agile;

  .que1 {
    background: #FF5F84;
    border-radius: 1rem;
    color: #FBF4E6;
    padding: 5vh;
    width: 30%;
    height: 20vh;
    align-content: justify;
    //padding: 10px;
    transform: skew(-20deg);
    font-size: 20px;
  }

  .que2 {
    background: #FF8C2A;
    border-radius: 1rem;
    color: black;
    padding: 5vh;
    min-width: 20%;
    height: 20vh;
    align-content: justify;
    transform: skew(20deg);
    font-size: 20px;

  }

  .ans1 {
    background: #FF5F84;
    border-radius: 1rem;
    //transform: skew(-20deg);
    color: #FBF4E6;
    padding: 5vh;
    width: 75%;
    font-weight: 400;
    line-height: 3vh;
    height: 20vh;
    font-size: 20px;
  }

  .ans2 {
    background: #FF8C2A;
    border-radius: 1rem;
    color: black;
    //transform: skew(20deg);
    padding: 5vh;
    width: 100%;
    font-weight: 400;
    line-height: 3vh;
    min-height: 20vh;
    font-size: 20px;
  }

  .box1 .box3 {
    width: 100%;
  }

  .box3 {
    margin-left: 60%;
  }

  .ans1,
  .box1:hover .que1 {
    display: none;
  }

  .box1:hover .ans1 {
    display: flex;
  }

  .ans2,
  .box3:hover .que2 {
    display: none;
  }

  .box3:hover .ans2 {
    display: block;
  }

}

@media only screen and (orientation: portrait) {
  // @mixin agile {
  //   display: grid;
  //   grid-template-columns: 50% 50%;
  //   width: 100%;

  // }

  .Agile {
    background: #101728;
    color: #FBF4E6;
    height: 100vh;
    border: 1vh solid #101728;
  }

  .header {
    margin-top: -4vh;
    margin-left: 3vh;
  }

  .title {
    font-size: 40px;
    padding: 2px;
  }

  .para {
    font-size: 18px;
    // margin-top: -5vh;
    // margin-bottom: 5vh;
    color: burlywood;
    padding: 2px;
  }

  .main-box {
    width: 100%;
    //@include agile;

    .que1 {
      background: #FF5F84;
      margin-left: 2vh;
      border-radius: 1rem;
      color: #FBF4E6;
      width: 60%;
      min-height: 25vh;
      max-height: 40vh;
      align-content: justify;
      padding: 1vh;
      transform: skew(0deg);
      // font-size: 16px;
    }

    .que2 {
      background: #FF8C2A;
      margin-right: 2vh;
      border-radius: 1rem;
      color: black;
      // padding: 2vh;
      min-width: 50%;
      height: 20vh;
      align-content: justify;
      transform: skew(0deg);
      // font-size: 16px;
    }

    .ans1 {
      background: #FF5F84;
      border-radius: 1rem;
      //transform: skew(-20deg);
      color: #FBF4E6;
      padding: 2vh;
      width: 85%;
      font-weight: 400;
      line-height: 3vh;
      height: fit-content;
      font-size: 16px;
    }

    .ans2 {
      background: #FF8C2A;
      border-radius: 1rem;
      color: black;
      //transform: skew(20deg);
      padding: 3vh;
      width: 140%;
      font-weight: 400;
      line-height: 3vh;
      min-height: fit-content;
      position: relative;
      left: -12vh;
      font-size: 16px;
    }

    .box1 .box3 {
      width: 100%;
    }

    .box3 {
      margin-left: 40%;
    }

    .ans1,
    .box1:hover .que1 {
      display: none;
    }

    .box1:hover .ans1 {
      display: flex;
    }

    .ans2,
    .box3:hover .que2 {
      display: none;
    }

    .box3:hover .ans2 {
      display: block;
    }
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  // @mixin agile {
  //   display: grid;
  //   grid-template-columns: 50% 50%;
  //   width: 100%;

  // }

  .Agile {
    background: #101728;
    color: #FBF4E6;
    height: 100vh;
    border: 1vh solid #101728;
  }

  .header {
    margin-top: -10vh;
    margin-left: 3vh;
  }

  .title {
    font-size: 8vh;
  }

  .para {
    font-size: 20px;
    margin-top: -5vh;
    margin-bottom: 5vh;
    color: burlywood;
  }

  .main-box {
    width: 100%;
    //@include agile;

    .que1 {
      background: #FF5F84;
      margin-left: 2vh;
      border-radius: 1rem;
      color: #FBF4E6;
      width: 50%;
      height: fit-content;
      align-content: justify;
      padding: 3vh;
      transform: skew(0deg);
      font-weight: 600;
      line-height: 5vh;
    }

    .que2 {
      background: #FF8C2A;
      margin-right: 2vh;
      border-radius: 1rem;
      color: black;
      padding: 3vh;
      min-width: 25%;
      height: fit-content;
      align-content: justify;
      transform: skew(0deg);
      font-weight: 600;
      line-height: 5vh;
      position: relative;
      left: -20vh;

    }

    .ans1 {
      background: #FF5F84;
      border-radius: 1rem;
      //transform: skew(-20deg);
      color: #FBF4E6;
      padding: 3vh;
      width: 80%;
      font-weight: 400;
      line-height: 5vh;
      height: fit-content;
    }

    .ans2 {
      background: #FF8C2A;
      border-radius: 1rem;
      color: black;
      //transform: skew(20deg);
      padding: 2vh;
      width: 180%;
      font-weight: 400;
      line-height: 4.5vh;
      min-height: fit-content;
      position: relative;
      left: -85vh;
    }

    .box1 .box3 {
      width: 100%;
    }

    .box3 {
      margin-left: 60%;
    }

    .ans1,
    .box1:hover .que1 {
      display: none;
    }

    .box1:hover .ans1 {
      display: flex;
    }

    .ans2,
    .box3:hover .que2 {
      display: none;
    }

    .box3:hover .ans2 {
      display: block;
    }
  }

}